import axiosInstance from '../axiosInstance';

export async function listUnits(connectorId, environmentId, tenantId) {
    let params = { connectorId };
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.get('/Unit', { params })
        .then((response) => response.data);
}