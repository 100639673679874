import axiosInstance from '../axiosInstance';

export async function listCompany(connectorId, environmentId, tenantId) {    

    let params = { connectorId };
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.get(`/Company`, { params })
        .then((response) => response.data);
}