import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

function Logout() {
    const appInsights = useAppInsightsContext();
    appInsights.trackPageView("Logout");

    return (
        <div className="Logout">
           Logout PAGE
        </div>
    );
}

export default Logout;
