import axiosInstance from '../axiosInstance';

export async function getReasonCodes(query, environmentId, tenantId) {    
    let { name, ...params } = query || {};

    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.get('/connectors/subscriptionManagement/ReasonCode/', { params })
        .then((response) => response.data);
}

export async function getReasonCode(id, environmentId, tenantId) {
    let params = {};
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.get(`/connectors/subscriptionManagement/ReasonCode/${id}`, { params })
        .then((response) => response.data);
}

export async function createReasonCode(data, environmentId, tenantId) {
    let params = {};
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.post('/connectors/subscriptionManagement/ReasonCode/', data, { params })
        .then((response) => response.data);
}

export async function updateReasonCode(id, data, environmentId, tenantId) {
    let params = {};
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.patch(`/connectors/subscriptionManagement/ReasonCode/${id}`, data, { params })
        .then((response) => response.data);
}

export async function deleteReasonCode(id, environmentId, tenantId) {
    let params = {};
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.delete(`/connectors/subscriptionManagement/ReasonCode/${id}`, { params })
        .then((response) => response.data);
}