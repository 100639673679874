import {   
    useNavigate,
    useLocation,
} from 'react-router-dom';

export const useNavigateWithParams = () => {
    const location = useLocation();
    const navigate = useNavigate();
    return (to, options) => {        
        return navigate(to + location.search, options);
    }
};