import axiosInstance from '../axiosInstance';

export async function getSubscriptionPlanLines(subscriptionPlanId, subscriptionPlanLineId, environmentId, tenantId) {
    let params = {};
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    params.excludePO = true;

    return await axiosInstance.get(
        `connectors/subscriptionManagement/SubscriptionPlan/${subscriptionPlanId}/SubscriptionPlanLine/${subscriptionPlanLineId}`, { params })
        .then((response) => response.data);
}

export async function listSubscriptionPlanLines(subscriptionPlanId, environmentId, tenantId) {
    let params = {};
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    params.excludePO = true;
    return await axiosInstance.get(`connectors/subscriptionManagement/SubscriptionPlan/${subscriptionPlanId}/SubscriptionPlanLine`, { params })
        .then((response) => response.data);
}
export async function EnableSubscriptionPlanLine(subscriptionPlanId, subscriptionPlanLineId, environmentId, tenantId) {
    let params = {};
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.put(`connectors/subscriptionManagement/SubscriptionPlan/${subscriptionPlanId}/SubscriptionPlanLine/${subscriptionPlanLineId}/enable`, {}, { params })
        .then((response) => response.data);
}

export async function DisableSubscriptionPlanLine(subscriptionPlanId, subscriptionPlanLineId, environmentId, tenantId) {
    let params = {};
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.put(`connectors/subscriptionManagement/SubscriptionPlan/${subscriptionPlanId}/SubscriptionPlanLine/${subscriptionPlanLineId}/disable`, {}, { params })
        .then((response) => response.data);
}

export async function DeleteSubscriptionPlanLine(subscriptionPlanId, subscriptionPlanLineId, environmentId, tenantId) {
    let params = {};
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.delete(`connectors/subscriptionManagement/SubscriptionPlan/${subscriptionPlanId}/SubscriptionPlanLine/${subscriptionPlanLineId}`, { params })
        .then((response) => response.data);
}

export async function createSubscriptionPlanLine(subscriptionPlanId, data, environmentId, tenantId) {
    let params = {};
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.post(`connectors/subscriptionManagement/SubscriptionPlan/${subscriptionPlanId}/SubscriptionPlanLine/`, data, { params })
        .then((response) => response.data);
}

export async function updateSubscriptionPlanLine(subscriptionPlanId, subscriptionPlanLineId, data, environmentId, tenantId) {
    let params = {};
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.patch(`connectors/subscriptionManagement/SubscriptionPlan/${subscriptionPlanId}/SubscriptionPlanLine/${subscriptionPlanLineId}`, data, { params })
        .then((response) => response.data);
}

export async function AddCreditNote(subscriptionPlanId, subscriptionPlanLineId, data, environmentId, tenantId) {
    let params = {};
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.post(`connectors/subscriptionManagement/SubscriptionPlan/${subscriptionPlanId}/SubscriptionPlanLine/${subscriptionPlanLineId}/CreditNote`, data, { params })
        .then((response) => response.data);
}