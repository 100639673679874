import axiosInstance from '../axiosInstance';

export async function getConnectors(environmentId, tenantId) {    
    let environmentTenantUrl = `?environmentId=${environmentId}`;
    if (tenantId)
        environmentTenantUrl += `&tenantId=${tenantId}`;

    return await axiosInstance.get(`/Connector${environmentTenantUrl}`)
        .then((response) => response.data);
}
export async function getFilteredConnectors(removeType,environmentId, tenantId) {
    let environmentTenantUrl = `?environmentId=${environmentId}`;
    environmentTenantUrl += `&removeType=${removeType}`;
    if (tenantId)
        environmentTenantUrl += `&tenantId=${tenantId}`;
    
    return await axiosInstance.get(`/Connector${environmentTenantUrl}`)
        .then((response) => response.data);
}
export async function getConnector(id, environmentId, tenantId) {
    let environmentTenantUrl = `?environmentId=${environmentId}`;
    if (tenantId)
        environmentTenantUrl += `&tenantId=${tenantId}`;
    return await axiosInstance.get(`/Connector/${id}${environmentTenantUrl}`)
        .then((response) => response.data);
}

export async function createInitialConnector(connectorId, environmentId, tenantId) {
    let environmentTenantUrl = `?environmentId=${environmentId}`;
    if (tenantId)
        environmentTenantUrl += `&tenantId=${tenantId}`;

    return await axiosInstance.post(`/Connector${environmentTenantUrl}`, {id: connectorId})
        .then((response) => response.data);
}

export async function createConnector(id, data, environmentId, tenantId) {
    let environmentTenantUrl = `?environmentId=${environmentId}`;
    if (tenantId)
        environmentTenantUrl += `&tenantId=${tenantId}`;

    return await axiosInstance.patch(`/Connector/${id}${environmentTenantUrl}`, data)
        .then((response) => response.data);
}

export async function activateConnector(id, environmentId, tenantId) {
    let environmentTenantUrl = `?environmentId=${environmentId}`;
    if (tenantId)
        environmentTenantUrl += `&tenantId=${tenantId}`;

    return await axiosInstance.post(`/Connector/${id}/Activate${environmentTenantUrl}`)
        .then((response) => response.data);
}


export async function deactivateConnector(id, environmentId, tenantId) {
    let environmentTenantUrl = `?environmentId=${environmentId}`;
    if (tenantId)
        environmentTenantUrl += `&tenantId=${tenantId}`;

    return await axiosInstance.post(`/Connector/${id}/Deactivate${environmentTenantUrl}`)
        .then((response) => response.data);
}


export async function deleteConnector(id, environmentId, tenantId) {
    let environmentTenantUrl = `?environmentId=${environmentId}`;
    if (tenantId)
        environmentTenantUrl += `&tenantId=${tenantId}`;

    let params = { connectorId: id };
    if (environmentId)
        params.environmentId = environmentId;
    if (tenantId)
        params.tenantId = tenantId;


    let haveMerchants = await axiosInstance.get('/connectors/paymentGateway/Merchant/', { params })
        .then((response) => response.data);
    if (haveMerchants.length === 0) {
        await axiosInstance.delete(`/Connector/${id}${environmentTenantUrl}`)
            .then((response) => response.data);
        return { type: "success", message: "Connector Deleted Sucessfully." };
    } else {
        return { type: "error", message: "Connector Attached to Merchant, cannot be deleted." };
    }
}

export async function testConnector(id, environmentId, tenantId) {
    let environmentTenantUrl = `?environmentId=${environmentId}`;
    if (tenantId)
        environmentTenantUrl += `&tenantId=${tenantId}`;

    return await axiosInstance.get(`/Connector/${id}/test${environmentTenantUrl}`)
        .then((response) => response.data);
}