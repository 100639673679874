import axiosInstance from '../axiosInstance';

export async function listCurrencies(connectorId, environmentId, tenantId) {
    let params = { connectorId };
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;
    return await axiosInstance.get('/Currency', { params })
        .then((response) => response.data);
}