import axiosInstance from '../axiosInstance';

export async function listCustomers(connectorId, environmentId, tenantId) {
    let params = { };
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    if (connectorId) 
        params.connectorId = connectorId;

    return await axiosInstance.get('/Customer', { params })
    .then((response) => response.data);
}
export async function listCustomersByConnectorId(connectorId, environmentId, tenantId) {
    let params = {};
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    if (connectorId)
        params.connectorId = connectorId;

    params.filterByConnectorId = true;

    return await axiosInstance.get('/Customer', { params })
        .then((response) => response.data);
}