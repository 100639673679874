import axiosInstance from '../axiosInstance';

export async function getProgramTypes(environmentId, tenantId) {
    let params = { };
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.get('/connectors/subscriptionManagement/ProgramType/', { params })
        .then((response) => response.data);
}

export async function getProgramType(id, environmentId, tenantId) {
    let params = { };
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.get(`/connectors/subscriptionManagement/ProgramType/${id}`, { params })
        .then((response) => response.data);
}

export async function createProgramType(data, environmentId, tenantId) {
    let params = { };
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.post('/connectors/subscriptionManagement/ProgramType/', data, { params })
        .then((response) => response.data);
}

export async function deleteProgramType(id, environmentId, tenantId) {
    let params = { };
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.delete(`/connectors/subscriptionManagement/ProgramType/${id}`, { params })
        .then((response) => response.data);
}