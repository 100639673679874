import axios from 'axios';
import { pca } from './authentication/publicClientApplication';
import fetchAccessToken from './api/fetchAccessToken';

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
    transformResponse: function (data) {
        if (!data || data === "") return {};
        //this block is responsible for deserializing $ref into the actual object
        var refMap = {};
        return JSON.parse(data, function (key, value) {
            if (key === '$id') {
                refMap[value] = this;
                // return undefined so that the property is deleted
                return void (0);
            }

            if (value && value.$ref) { return refMap[value.$ref]; }
            return value;
        });
    }
});

instance.interceptors.request.use(function (config) {

    return fetchAccessToken(pca)
        .then((accessToken) => {
            config.headers["Authorization"] = `Bearer ${accessToken}`;
            return config;
        })
        .catch((e) => {
            console.log('Failed to aquire access token for HARP API', e);
            let searchParams = document.location.search.match("layout=embedded")
            if (searchParams !== null) {
                return config;
            } else {
                localStorage.clear();
                document.location.reload();
                return false;
            }
        });   
});

//instance.defaults.headers.common['Authorization'] = 'AUTH token';
instance.defaults.headers.post['Content-Type'] = 'application/json';
instance.defaults.headers.patch['Content-Type'] = 'application/json';
instance.defaults.headers.put['Content-Type'] = 'application/json';

export default instance;