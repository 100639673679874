import {  createContext } from 'react';

const UserContext = createContext({
    environments: null,
    activeEnvironment: null,
    isSandbox: true,
    tenant: null,
    setEnvironments: () => { },
    setActiveEnvironment: (environmentId, redirect) => { },
    setTenant: (tenantId) => { }
});


export default UserContext;
