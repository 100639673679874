import axiosInstance from '../axiosInstance';

export async function listMerchants(query, environmentId, tenantId) {
    let params = { ...query };
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;


    return await axiosInstance.get('/connectors/paymentGateway/Merchant/', { params })
        .then((response) => response.data);

}

export async function getMerchant(merchantId, environmentId, tenantId) {
    let params = {};
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.get('/connectors/paymentGateway/Merchant/' + merchantId, { params })
        .then((response) => response.data);
}

export async function addMerchant(data, environmentId, tenantId) {

    let params = {};
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.post('/connectors/paymentGateway/Merchant/', data, { params })
        .then((response) => response.data);
}

export async function testMerchantConnection(merchantId, environmentId, tenantId) {
    let params = {};
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.get('/connectors/paymentGateway/merchant/' + merchantId + '/Test', { params })
        .then((response) => response.data);
}

export async function deleteMerchant(id, environmentId, tenantId) {
    let environmentTenantUrl = `?environmentId=${environmentId}`;
    if (tenantId)
        environmentTenantUrl += `&tenantId=${tenantId}`;

    return await axiosInstance.delete(`/connectors/paymentGateway/Merchant/${id}${environmentTenantUrl}`)
        .then((response) => response.data);
}

export async function updateMerchant(merchantId, data, environmentId, tenantId) {
    let params = {};
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.patch(`/connectors/paymentGateway/Merchant/` + merchantId, data, { params })
        .then((response) => response.data);
}