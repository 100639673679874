import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, Fragment } from "react";
import { BrowserAuthError, InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import { styled } from '@mui/material';
import { msalProviderPopupConfig } from './authConfig';

const StyledTypography = styled(Typography)({
    wordBreak: "break-word",
    textAlign: "center",
    color: "white",
    "fontWeight": "800",
    "fontFamily": "Paralucent,Mulish,Muli,sans-serif"
});

const isSiteOffline = process.env.REACT_APP_SITE_OFFLINE === "true" ?? false;

const delay = t => new Promise(resolve => setTimeout(resolve, t));

function RedirectLogin({hideRedirectText }) {
    const appInsights = useAppInsightsContext();
    appInsights.trackPageView("RedirectLogin");

    const { instance, inProgress, accounts } = useMsal();
    const [isLoading, setIsloading] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        if (inProgress === InteractionStatus.None) {
            const request = msalProviderPopupConfig.silentRequestConfig;
            request.account = accounts[0];

            setIsloading(true);
            instance.acquireTokenSilent(request).then((accessTokenResponse) => {
                // Acquire token silent success
                setIsloading(false);
                if (!isSiteOffline) {
                    return delay(3000)
                        .then(() => navigate("/"));
                }

            }).catch((error) => {
                if (error instanceof InteractionRequiredAuthError ||
                    error instanceof BrowserAuthError) {
                    instance.acquireTokenRedirect(request)
                        .then(() => navigate("/"))
                        .catch((e) => {
                            console.log(e);
                        });
                }
            });
        }
    }, [instance, accounts, inProgress, navigate]);

    return (
        <Fragment>
            {hideRedirectText === false && (<Fragment>< CssBaseline />
            <Box display="flex"
                justifyContent="center"
                sx={{ bgcolor: 'rgba(0,63,166)', height: '100vh', maxWidth: '100vw'}}>
                    <Stack alignItems="center" direction={'column'} spacing={12} sx={{ margin: "auto" }}>
                    <Container maxWidth="false">
                            <StyledTypography variant="h2">Signing in</StyledTypography>
                        <StyledTypography style={{ color: "darkgrey" }} variant="h4">We're getting everything ready for you :)</StyledTypography>
                    </Container>
                    {isLoading &&
                        (<Fade in={isLoading}>
                            <CircularProgress size="10rem" />
                        </Fade>)}
                </Stack>
                </Box>
                </Fragment>)
            }

        </Fragment>
    )
}

RedirectLogin.defaultProps = {
    hideRedirectText: false
}
export default RedirectLogin;