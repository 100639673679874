import axiosInstance from '../axiosInstance';

export async function listSubscriptionActions(query, environmentId, tenantId) {
    let params = { ...query };
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    params.excludePO = true;

    return await axiosInstance.get('/connectors/subscriptionManagement/SubscriptionAction/', {params})
        .then((response) => response.data);
    

//    subscriptionPlanLineId
}

export async function getSubscriptionAction(subscriptionActionId, environmentId, tenantId) {
    let params = { };
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.get('/connectors/subscriptionManagement/SubscriptionAction/' + subscriptionActionId, { params })
        .then((response) => response.data);
}

export async function firmSubscriptionAction(subscriptionActionId, environmentId, tenantId) {
    let params = { };
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.put('/connectors/subscriptionManagement/SubscriptionAction/' + subscriptionActionId + "/Firm", {}, { params })
        .then((response) => response.data);
}

export async function firmSubscriptionActions(subscriptionActionIds, environmentId, tenantId) {
    let params = {};
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.put('/connectors/subscriptionManagement/SubscriptionAction/Firm', { subscriptionActionIds }, { params })
        .then((response) => response.data);
}

export async function postSubscriptionAction(subscriptionActionId, environmentId, tenantId) {
    let params = {};
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.put('/connectors/subscriptionManagement/SubscriptionAction/' + subscriptionActionId + "/Post", {}, { params })
        .then((response) => response.data);
}

export async function postSubscriptionActions(subscriptionActionIds, environmentId, tenantId) {
    let params = {};
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.put('/connectors/subscriptionManagement/SubscriptionAction/Post', {
        SubscriptionActions: subscriptionActionIds.map((a) => { return { "SubscriptionActionId": a } })
    }, { params })
        .then((response) => response.data);
}

export async function cancelSubscriptionAction(subscriptionActionId, body, environmentId, tenantId) {
    let params = {};
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.put('/connectors/subscriptionManagement/SubscriptionAction/' + subscriptionActionId + "/Cancel", { reasonCode: body }, { params })
        .then((response) => response.data);
}

export async function cancelSubscriptionActions(subscriptionActionIds, reasonCode, environmentId, tenantId) {
    let params = {};
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.put('/connectors/subscriptionManagement/SubscriptionAction/Cancel', { subscriptionActionIds, reasonCode }, { params })
        .then((response) => response.data);
}