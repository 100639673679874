import axiosInstance from '../axiosInstance';

export async function listProducts(connectorId, environmentId, tenantId) {
    let params = { connectorId };
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.get('/Product', { params })
        .then((response) => response.data);
}