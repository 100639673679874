import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.REACT_APP_INSTRUMENTATIONKEY ?? "missing-instrumentation-key",
        extensions: [reactPlugin],
        enableAutoRouteTracking: true,
    }
});

appInsights.loadAppInsights();
export { reactPlugin, appInsights };