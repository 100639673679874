import * as msal from "@azure/msal-browser";

const clientId = process.env.REACT_APP_AAD_APP_CLIENTID ?? "missing-client-id";
const tenantId = process.env.REACT_APP_AAD_DIRECTORY_TENANTID ?? "missing-tenant-id";
const redirectUri = process.env.REACT_APP_AAD_REDIRECT_URI ?? "missing-redirect-uri";
const scopes = (process.env.REACT_APP_AAD_SCOPES ?? "missing-scopes").split(' ');
//const commonAuthority = `https://login.microsoftonline.com/common`; 
const tenantAuthority = `https://login.microsoftonline.com/${tenantId}`;

const msalProviderPopupConfig = {
    type: "popup",
    msalConfig: {
        auth: {
            clientId: clientId,
            authority: tenantAuthority,
            redirectUri: redirectUri
        },
        cache: {
            cacheLocation: "localStorage",
        },
        system: {
            loggerOptions: {
                loggerCallback: (level, message, containsPii) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case msal.LogLevel.Error:
                            console.error(message);
                            return;
                        case msal.LogLevel.Info:
                            console.info(message);
                            return;
                        case msal.LogLevel.Verbose:
                            console.debug(message);
                            return;
                        case msal.LogLevel.Warning:
                            console.warn(message);
                            return;
                        default:
                            return;
                    }
                }
            }
        }
    },
    silentRequestConfig: {
        scopes: scopes
    },
    endSessionRequestConfig: {
    },
    loginRequestConfig: {
        scopes: scopes
    }
}

export { msalProviderPopupConfig };