import axiosInstance from '../axiosInstance';

export async function getSubscriptionPlans(activeCustomer, selectedConnectorId, environmentId, tenantId) {
    let params = {};
    params.getFromHarp = false;
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    if (activeCustomer) {
        if (activeCustomer.connector)
            params.connectorId = activeCustomer.connector;

        params.customerId = activeCustomer.id;
    }
    if (selectedConnectorId) {
        params.connectorId = selectedConnectorId;
        params.getFromHarp = true;
    }
    params.excludePO = true;

    return await axiosInstance.get(`connectors/subscriptionManagement/SubscriptionPlan`, { params })
        .then((response) => response.data);        
}

export async function getSubscriptionPlan(subscriptionPlanId, environmentId, tenantId) {
    let params = {};
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.get(`/connectors/subscriptionManagement/SubscriptionPlan/${subscriptionPlanId}`, { params })
        .then((response) => response.data);
}
export async function publishSubscriptionPlan(id, environmentId, tenantId) {
    let params = {};
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.put(`/connectors/subscriptionManagement/SubscriptionPlan/${id}/publish`, {}, { params })
        .then((response) => response.data);
}

export async function draftSubscriptionPlan(id, environmentId, tenantId) {
    let params = {};
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.put(`/connectors/subscriptionManagement/SubscriptionPlan/${id}/draft`, {}, { params })
        .then((response) => response.data);
}

export async function cancelSubscriptionPlan(id, body, environmentId, tenantId) {
    let params = {};
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.put(`/connectors/subscriptionManagement/SubscriptionPlan/${id}/cancel`, { reasonCode: body }, { params })
        .then((response) => response.data);
}

export async function createSubscriptionPlan(data, environmentId, tenantId) {
    let params = {};
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.post('/connectors/subscriptionManagement/SubscriptionPlan/', data, { params })
        .then((response) => response.data);
}

export async function masterProcess(subscriptionPlanId, data, environmentId, tenantId) {
    let params = {};
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.put(`/connectors/subscriptionManagement/SubscriptionPlan/${subscriptionPlanId}/harpconnect`, data, { params })
        .then((response) => response.data);
}

export async function updateContractDefinition(subscriptionPlanId, data, environmentId, tenantId) {
    let params = {};
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.put(`/connectors/subscriptionManagement/SubscriptionPlan/${subscriptionPlanId}/contractDefinition`, data, { params })
        .then((response) => response.data);
}

export async function updateSubscriptionPlan(subscriptionPlanId, data, environmentId, tenantId) {
    let params = {};
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.patch(`connectors/subscriptionManagement/SubscriptionPlan/${subscriptionPlanId}`, data, { params })
        .then((response) => response.data);
}