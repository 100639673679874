import axiosInstance from '../axiosInstance';

export async function listEnvironment(connectorId, environmentId, tenantId, isSandbox) {
    let params = {};
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    if (connectorId)
        params.connectorId = connectorId;

    if (isSandbox != null)
        params.isSandbox = isSandbox;

    return await axiosInstance.get(`/Environment`, { params })
        .then((response) => response.data);    
}