import axiosInstance from '../axiosInstance';

export async function getRevenue(startBy, finishBy, format, type, environmentId, tenantId) {
    let params = { startBy, finishBy, format, type };
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.get('/dashboard/revenue', { params })
        .then((response) => response.data);
}

export async function getChurn(startBy, finishBy, format, type, environmentId, tenantId) {
    let params = { startBy, finishBy, format, type };
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.get('/dashboard/churn', { params })
        .then((response) => response.data);
}

export async function getCustomers(startBy, finishBy, format, type, environmentId, tenantId) {
    let params = { startBy, finishBy, format, type };
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.get('/dashboard/customers', { params })
        .then((response) => response.data);
}

export async function getActionsCreated(startBy, finishBy, format, type, environmentId, tenantId) {
    let params = { startBy, finishBy, format, type };
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.get('/dashboard/actions-created', { params })
        .then((response) => response.data);
}

export async function getActionsPosted(startBy, finishBy, format, type, environmentId, tenantId) {
    let params = { startBy, finishBy, format, type };
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.get('/dashboard/actions-posted', { params })
        .then((response) => response.data);
}

export async function getActionsFirmed(startBy, finishBy, format, type, environmentId, tenantId) {
    let params = { startBy, finishBy, format, type };
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.get('/dashboard/actions-firmed', { params })
        .then((response) => response.data);
}

export async function getCards(startBy, finishBy, environmentId, tenantId) {
    let params = { startBy, finishBy };
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.get('/dashboard/cards', { params })
        .then((response) => response.data);
}