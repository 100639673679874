import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/material";
import queryString from "query-string";
import { performGoCardlessAuthentication } from "../api/goCardlessService";

const StyledTypography = styled(Typography)({
    wordBreak: "break-word",
    textAlign: "center",
    color: "white",
    fontWeight: "800",
    fontFamily: "Paralucent,Mulish,Muli,sans-serif",
});

function GoCardlessRedirect() {
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = queryString.parse(location.search);

     useEffect(() => {
        const code = queryParams.code;
        const state = queryParams.state;

        if (code != null && state != null) {
            //call the api
            //after calling api, redirect
            performGoCardlessAuthentication(code, state)
                 .then(() => setIsLoading(false))
                .then(() => navigate("/"))
                .catch((e) => {
                    setIsLoading(false);
                    setErrorMessage(e.errorMessage);
                    console.log(e);
                })

        }
    }, [navigate, queryParams.code, queryParams.state]);

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            bgcolor="rgba(0, 63, 166)"
        >
            <Box textAlign="center">
                <StyledTypography variant="h2">
                    Authentication Completed
                </StyledTypography>
                {isLoading ? (
                    <CircularProgress size="10rem" color="secondary" />
                ) : (
                     <Typography variant="h4" style={{ color: "darkgrey" }}>
                        You have successfully completed the authentication with GoCardless.
                    </Typography>
                )}
            </Box>
        </Box>
    );
}

export default GoCardlessRedirect;