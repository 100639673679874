import axiosInstance from '../axiosInstance';

export async function listUsers(tenantId) {
    let params = {};
    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.get('/user', { params })
        .then((response) => response.data);
}

export async function getUser(userId, tenantId) {
    let params = {};
    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.get('/user/' + userId, { params })
        .then((response) => response.data);
}

export async function createUser(user, tenantId) {
    let params = {};
    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.post('/user', user, { params })
        .then((response) => response.data);
}

export async function activateUser(userId, tenantId) {
    let params = {};
    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.put('/user/' + userId + "/Activate", {}, { params })
        .then((response) => response.data);
}

export async function deactivateUser(userId, tenantId) {
    let params = {};
    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.put('/user/' + userId + "/Deactivate", {}, { params })
        .then((response) => response.data);
}

export async function removeUser(userId, tenantId) {
    let params = {};
    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.delete('/user/' + userId, { params })
        .then((response) => response.data);
}

export async function removeUserRole(userId, tenantId) {
    let params = {};
    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.patch('/user/' + userId + "/Role/Remove", {}, { params })
        .then((response) => response.data);
}

export async function updateUserDetails(userId, data, tenantId) {
    let params = {};
    if (tenantId)
        params.tenantId = tenantId;
    debugger;
    return await axiosInstance.patch('/user/' + userId, data, { params })
        .then((response) => response.data);
}

export async function updateUserRole(userId, data, tenantId) {
    let params = {};
    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.patch('/user/' + userId + "/Role/Update", data, { params })
        .then((response) => response.data);
}