import axiosInstance from '../axiosInstance';

export async function listPayments(query, environmentId, tenantId) {
    let params = { ...query };
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;


    return await axiosInstance.get('/connectors/paymentGateway/payment/', { params })
        .then((response) => response.data);

}

export async function getPayment(paymentId, environmentId, tenantId) {
    let params = {};
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.get('/connectors/paymentGateway/payment/' + paymentId, { params })
        .then((response) => response.data);
}

export async function cancelPayment(paymentId, body, environmentId, tenantId) {
    let params = {};
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.put('/connectors/paymentGateway/payment/' + paymentId + "/Cancel", { reasonCode: body }, { params })
        .then((response) => response.data);
}

export async function addPayment(environmentId, tenantId) {
    let params = {};
    if (environmentId)
        params.environmentId = environmentId;

    if (tenantId)
        params.tenantId = tenantId;

    return await axiosInstance.put('/connectors/paymentGateway/payment/', { params })
        .then((response) => response.data);
}




